import React from "react";
import { useState, useEffect } from 'react';
import { utils } from 'near-api-js'; 
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export const Create = ({ market, ft, precision }) => {

  function create() {
    market.ftTransfer(ft, formValues.amount, formValues.price, precision);
  }

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [balance, setBalance] = useState([]);

  useEffect(() => {
    market.ft_balance_of(ft).then(setBalance);
  }, [market, ft]);

  const defaultValues = {
    amount: '',
    price: ''
  };

  const [formValues, setFormValues] = useState(defaultValues);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  return (
    <>
      <Button variant="outlined" onClick={handleOpen}>CREATE</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container justifyContent={"center"}>
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={2}
            >
              <Grid item sm={"auto"} container direction="column">
                <Typography sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light'  }}>Contract</Typography>
                  {ft}
              </Grid>
              <Grid item sm={"auto"} container direction="column">
                <Typography sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light'  }}>Balance</Typography>
                {Math.floor(balance/precision)}
              </Grid>
              <Grid item sm={"auto"} container direction="column">
                <TextField
                  required
                  fullWidth
                  id="amount"
                  name="amount"
                  label="Amount"
                  type="number"
                  value={formValues.amount}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item sm={"auto"} container direction="column">
                <TextField
                  required
                  fullWidth
                  id="price"
                  name="price"
                  label="Price per Token"
                  type="number"
                  value={formValues.price}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item sm={"auto"} container direction="column">
                <Button variant="contained" onClick={create}>List</Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}