import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { utils } from 'near-api-js';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export default function Main ({ market, wallet }) {

  const [activeFt, getActiveFt] = useState([]);

  useEffect(() => {
    market.get_active_ft().then(getActiveFt);
  }, [market]);


  const [ftMeta, getMeta] = useState([]);

  const [ftFloor, getFtFloor] = useState([]);

  const [ftLength, getFtLength] = useState([]);

  useEffect(() => {
    const getM = async () => {
      for (let i = 0; i < activeFt.length; i++)
        await market.ft_metadata(activeFt[i].ft).then((res) => {
          getMeta(ftMeta => [...ftMeta, res])
      });
    }
    getM();
  }, [market, activeFt]);


  useEffect(() => {
    const getL = async () => {
      for (let i = 0; i < activeFt.length; i++)
        await market.get_listing_ft_amount(activeFt[i].ft).then((res) => {
          getFtLength(ftLength => [...ftLength, res])
      });
    }
    getL();
  }, [market, activeFt]);

  useEffect(() => {
    const getF = async () => {
      for (let i = 0; i < activeFt.length; i++)
        await market.get_ft_floor(activeFt[i].ft).then((res) => {
          getFtFloor(ftFloor => [...ftFloor, res])
      });
    }
    getF();
  }, [market, activeFt]);



  return (
  <>
    <Grid container direction="column" justifyContent={"center"} align="center">
      <Grid item>
        <Typography variant="h4" sx={{ fontWeight: 800, letterSpacing: '.2rem' }} >
          WHAT IS VEX MARKET?
        </Typography>
      </Grid>
      <Grid item>
        <Typography sx={{ fontSize: 14, fontWeight: 200, letterSpacing: '.1rem' }}>
          Vex Market is the first p2p token marketplace on $NEAR built by <strong>Vex Labs.</strong> <br/>
          Start by listing your token and then wait for someone to puchase it!<br/>
          Only whole tokens can be listed or bought (e.g. 0.5 VAC will not be accepted).<br/>
          To get your token listed, connect with us on <a href="https://dsc.gg/vexedapesclub">discord</a>.<br/>
        </Typography>
      </Grid>
    </Grid>
    <br/><br/>
    <Grid container justifyContent={"center"} >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        width="90%"
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow sx={{ background: "#02070d" }}>
                <TableCell>
                  Image
                </TableCell>
                <TableCell>
                  Symbol
                </TableCell>
                <TableCell>
                  Name
                </TableCell>
                <TableCell>
                  Contract
                </TableCell>
                <TableCell>
                  Listings
                </TableCell>
                <TableCell>
                  Floor
                </TableCell>
                <TableCell>
                  ACTION
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {activeFt.map((ft, i) =>
              <TableRow hover key={i} sx={{ background: "#050d16" }}>
                <TableCell>
                  <img width="40" src={ftMeta[i]?.icon} alt={ft.ft} />
                </TableCell>
                <TableCell>
                  {ftMeta[i]?.symbol}
                </TableCell>
                <TableCell>
                  {ftMeta[i]?.name}
                </TableCell>
                <TableCell>
                  {ft.ft}
                </TableCell>
                <TableCell>
                  { Math.floor(ftLength[i]/ft.precision) || 0 }
                </TableCell>
                <TableCell>
                  {utils.format.formatNearAmount(ftFloor[i])} Ⓝ
                </TableCell>
                <TableCell>
                  <Button component={Link} to={`/${ft.ft}`}>VIEW</Button>
                </TableCell>
              </TableRow>
            )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  </>
  );
}
