import React from "react";
import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { utils } from "near-api-js";
import { Create } from "./Create";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";

/* global BigInt */

export default function Listings({ market, wallet }) {
  let { ft } = useParams();

  const [precision, getFtPrecision] = useState([]);

  useEffect(() => {
    market.get_ft_precision(ft).then(getFtPrecision);
  }, [market, ft]);

  const [nusd, getNusd] = useState([]);

  useEffect(() => {
    fetch(
      `https://api.coingecko.com/api/v3/simple/price?ids=near&vs_currencies=usd`
    )
      .then((response) => response.json())
      .then((actualData) => getNusd(actualData.near.usd))
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const [listing, getListing] = useState([]);

  useEffect(() => {
    market.get_listing_ft(ft, 0).then(getListing);
  }, [market, ft]);

  const [ftMeta, getMeta] = useState([]);

  useEffect(() => {
    market.ft_metadata(ft).then(getMeta);
  }, [market, ft]);

  const [ftFloor, getFtFloor] = useState([]);

  useEffect(() => {
    market.get_ft_floor(ft).then(getFtFloor);
  }, [market, ft]);

  const [ftAmount, getFtAmount] = useState([]);

  useEffect(() => {
    market.get_listing_ft_amount(ft).then(getFtAmount);
  }, [market, ft]);

  const [stor, getStor] = useState([]);

  useEffect(() => {
    market.storage_balance_of(ft).then(getStor);
  }, [market, ft]);

  const [ftSales, getFtSales] = useState([]);

  useEffect(() => {
    market.get_sales_ft(ft).then(getFtSales);
  }, [market, ft]);

  const [ftVol, getFtVol] = useState([]);

  useEffect(() => {
    market.get_sales_vol_ft(ft).then(getFtVol);
  }, [market, ft]);

  const [formValue, setFormValues] = useState([]);

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    const list = [...formValue];
    // it's an array element, not a keyed map/object,
    // so have to recreate the entire element
    list[index] = { ...list[index], value };
    setFormValues(list);
  };

  function buy_token(i, id, price) {
    let amount = formValue[i].value * 10000;
    const deposit =
      (BigInt(price) * BigInt(amount)) / BigInt(10000) +
      BigInt("200000000000000000000");
    if (stor?.total === "0") {
      market.storage_deposit(ft);
    } else {
      market.buy_token(id, formValue[i].value, deposit);
    }
  }

  function withdraw(i, id) {
    market.withdraw(id, formValue[i].value);
  }

  function displayWallet(wallet) {
    if (wallet.length > 16) {
      return `${wallet.substring(0, 13)}...`;
    } else {
      return `${wallet}`;
    }
  }

  return (
    <>
      <Button sx={{ pl: 4, pb: 1 }} color="secondary" component={Link} to="/">
        &lt; Home
      </Button>
      <Box sx={{ p: 2, mb: 6 }}>
        <Grid container justifyContent={"center"}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              container
              spacing={{ xs: 2, md: 6 }}
              alignItems="center"
              justifyContent="center"
              direction="row"
            >
              <Grid
                item
                sm={"auto"}
                spacing={{ xs: 2, md: 3 }}
                container
                direction="column"
              >
                <Grid item sm={"auto"}>
                  <img width="40" src={ftMeta.icon} alt={ft} />
                </Grid>
                <Grid item sm={"auto"}>
                  {wallet.accountId && (
                    <Create market={market} ft={ft} precision={precision} />
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                sm={"auto"}
                spacing={{ xs: 2, md: 3 }}
                container
                direction="column"
              >
                <Grid item>
                  <Typography
                    sx={{
                      color: "text.secondary",
                      fontSize: 12,
                      fontWeight: "light",
                    }}
                  >
                    Symbol
                  </Typography>
                  {ftMeta.symbol}
                </Grid>
                <Grid item sm={"auto"} container direction="column">
                  <Typography
                    sx={{
                      color: "text.secondary",
                      fontSize: 12,
                      fontWeight: "light",
                    }}
                  >
                    Floor
                  </Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                    {utils.format.formatNearAmount(ftFloor)} Ⓝ
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                sm={"auto"}
                spacing={{ xs: 2, md: 3 }}
                container
                direction="column"
              >
                <Grid item>
                  <Typography
                    sx={{
                      color: "text.secondary",
                      fontSize: 12,
                      fontWeight: "light",
                    }}
                  >
                    Name
                  </Typography>
                  {ftMeta.name}
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      color: "text.secondary",
                      fontSize: 12,
                      fontWeight: "light",
                    }}
                  >
                    Listed
                  </Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                    {Math.floor(ftAmount / precision) || 0}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                sm={"auto"}
                spacing={{ xs: 2, md: 3 }}
                container
                direction="column"
              >
                <Grid item>
                  <Typography
                    sx={{
                      color: "text.secondary",
                      fontSize: 12,
                      fontWeight: "light",
                    }}
                  >
                    Contract
                  </Typography>
                  {ft}
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      color: "text.secondary",
                      fontSize: 12,
                      fontWeight: "light",
                    }}
                  >
                    Volume
                  </Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                    {utils.format.formatNearAmount(ftSales)} Ⓝ
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Grid container justifyContent={"center"}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          width="95%"
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={{ background: "#02070d" }}>
                  <TableCell>ID</TableCell>
                  <TableCell>USER</TableCell>
                  <TableCell>AVAILABLE</TableCell>
                  <TableCell>PRICE</TableCell>
                  <TableCell>AMOUNT</TableCell>
                  <TableCell>COST</TableCell>
                  <TableCell>ACTION</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listing.map((list, i) => (
                  <TableRow hover key={i} sx={{ background: "#050d16" }}>
                    <TableCell>{list.id}</TableCell>
                    <TableCell>{displayWallet(list.user)}</TableCell>
                    <TableCell>
                      {Math.floor(list.available / precision)}
                    </TableCell>
                    <TableCell>
                      {utils.format.formatNearAmount(list.price)} Ⓝ
                      <Typography color="text.secondary" fontSize={12}>
                        $
                        {(
                          utils.format.formatNearAmount(list.price) * nusd
                        ).toFixed(2)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        label="Amount"
                        name={`${list.id}`}
                        value={list.value}
                        variant="outlined"
                        onChange={(e) => handleInputChange(e, i)}
                        type="number"
                        InputProps={{
                          inputProps: {
                            max: list.available,
                            min: 0,
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      {formValue[i]?.value && (
                        <Typography color="text.secondary" fontSize={12}>
                          {utils.format.formatNearAmount(list.price) *
                            formValue[i].value}{" "}
                          Ⓝ
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      {list.user === wallet.accountId ? (
                        <Button onClick={() => withdraw(i, list.id)}>
                          withdraw
                        </Button>
                      ) : (
                        <Button
                          onClick={() => buy_token(i, list.id, list.price)}
                        >
                          buy
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
}
