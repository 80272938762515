/* Talking with a contract often involves transforming data, we recommend you to encapsulate that logic into a class */
/* global BigInt */

import { utils } from 'near-api-js';


import { Buffer } from 'buffer';
window.Buffer = Buffer;

export class Market {

  constructor({ contractId, walletToUse }) {
    this.contractId = contractId;
    this.wallet = walletToUse
  }

  async get_active_ft() {
    return await this.wallet.viewMethod({ contractId: this.contractId, method: "get_active_ft", args: {} })
  }

  async get_ft_precision(ft) {
    return await this.wallet.viewMethod({ contractId: this.contractId, method: "get_ft_precision", args: { ft: ft } })
  }

  async get_listing_ft(ft, start) {
    return await this.wallet.viewMethod({ contractId: this.contractId, method: "get_listing_ft", args: { ft: ft, start: start } })
  }
  
  async get_ft_floor(ft) {
    return await this.wallet.viewMethod({ contractId: this.contractId, method: "get_ft_floor", args: { ft: ft } })
  }

  async get_listing_ft_length(ft) {
    return await this.wallet.viewMethod({ contractId: this.contractId, method: "get_listing_ft_length", args: { ft: ft } })
  }

  async get_listing_ft_amount(ft) {
    return await this.wallet.viewMethod({ contractId: this.contractId, method: "get_listing_ft_amount", args: { ft: ft } })
  }

  async get_sales_vol_ft(ft) {
    return await this.wallet.viewMethod({ contractId: this.contractId, method: "get_sales_vol_ft", args: { ft: ft } })
  }

  async get_sales_ft(ft) {
    return await this.wallet.viewMethod({ contractId: this.contractId, method: "get_sales_ft", args: { ft: ft } })
  }

  async buy_token(id, amount, deposit) {
    const gas = '300000000000000';
    return await this.wallet.callMethod({ contractId: this.contractId, method: "buy_token", args: { id: id, tokens: amount}, gas, deposit });
  }

  async withdraw(id, amount) {
    const gas = '300000000000000';
    const deposit = '200000000000000000000';
    return await this.wallet.callMethod({ contractId: this.contractId, method: "withdraw", args: { id: id, tokens: amount}, gas, deposit });
  }

  async ftTransfer(ft, a, p, precision) {
    const deposit = utils.format.formatNearAmount('1000000000000000000000000');
    const gas = '300000000000000';
    const price = utils.format.parseNearAmount(p);
    let am = BigInt(Math.floor(a)) * BigInt(precision);
    let amount = am.toString();
    return await this.wallet.callMethod({ contractId: ft, method: "ft_transfer_call", args: { receiver_id: this.contractId, amount: amount, msg: `{"price":"${price}"}` }, gas, deposit });
  }

  async ft_metadata(ft) {
    return await this.wallet.viewMethod({ contractId: ft, method: "ft_metadata", args: {} });
  }

  async storage_balance_of(ft) {
    return await this.wallet.viewMethod({ contractId: ft, method: "storage_balance_of", args: { account_id: this.wallet.accountId } });
  }

  async storage_deposit(ft) {
    const deposit = '1250000000000000000000';
    return await this.wallet.callMethod({ contractId: ft, method: "storage_deposit", args: { account_id: this.wallet.accountId }, deposit });
  }

  async ft_balance_of(ft) {
    return await this.wallet.viewMethod({ contractId: ft, method: "ft_balance_of", args: { account_id: this.wallet.accountId } });
  }

}